import {Box, Text} from '@mantine/core';
import type {FC} from 'react';
import {FaExternalLinkAlt} from 'react-icons/fa';
import {routes} from '../../../../config/constants';
import type {UserType} from '../../../../core/usecases/auth/AuthContext';
import {ReportMenu} from './ReportMenu';

type MenuItemProps = {
  item: {
    name: string;
    url: string;
    target?: string;
    isNew?: boolean;
    isUpdate?: boolean;
  };
  user?: UserType;
  handleMouseEnter: (item: {
    name: string;
    url: string;
    target?: string;
  }) => void;
  handleMouseLeave: (item: {
    name: string;
    url: string;
    target?: string;
  }) => void;
  shownReportMenu: boolean;
};

export const MenuItem: FC<MenuItemProps> = ({
  item,
  user,
  handleMouseEnter,
  handleMouseLeave,
  shownReportMenu,
}) => (
  <div
    onMouseEnter={() => {
      handleMouseEnter(item);
    }}
    onMouseLeave={() => {
      handleMouseLeave(item);
    }}
    className="relative"
  >
    <a
      href={
        item.url === routes.RECRUITINGS && user?.isEngineer
          ? `${item.url}?tagIDs=3`
          : item.url === routes.REPORTS
            ? '#'
            : item.url
      }
      target={item.target}
    >
      <span className="font-bold">{item.name}</span>
      {item.isNew && (
        <Box className="absolute text-accent-1 top-[-16px] left-0 right-0 mx-auto text-xs">
          <Text className="text-center">New</Text>
        </Box>
      )}
      {item.isUpdate && (
        <Box className="absolute text-accent-1 top-[-16px] left-0 right-0 mx-auto text-xs">
          <Text className="text-center">Update</Text>
        </Box>
      )}
      {item.target && (
        <span className="inline-flex">
          <FaExternalLinkAlt />
        </span>
      )}
    </a>
    {shownReportMenu && item.url === routes.REPORTS && (
      <div
        className={`absolute ${user && 'top-[10px]'} w-[100px] z-50 ml-[-12px] pt-12`}
      >
        <div className="overflow-hidden rounded-12 bg-background-1 shadow-modal">
          <ReportMenu />
        </div>
      </div>
    )}
  </div>
);
