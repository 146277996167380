import {Button} from '@mantine/core';
import type {FC} from 'react';
import {useBooleanState} from '../../../services/useBooleanState';
import {AuthActionMenu} from './AuthActionMenu';

export const LoginRegistButton: FC = () => {
  const [shownAuthActionMenu, showAuthActionMenu, hideAuthActionMenu] =
    useBooleanState(false);
  return (
    <div
      className="relative py-4"
      onMouseEnter={showAuthActionMenu}
      onMouseLeave={hideAuthActionMenu}
    >
      <Button
        className="text-accent-1 bg-transparent hover:bg-transparent pc:text-sm pc:bg-accent-2 pc:text-white pc:hover:bg-accent-1 pc:px-12 px-0"
        radius="xl"
      >
        ログイン・新規登録
      </Button>
      {shownAuthActionMenu && (
        <div className="absolute top-[40px] z-50 pt-12 left-[-20px]">
          <div className="overflow-hidden pc:rounded-12 bg-background-1 shadow-modal">
            <AuthActionMenu />
          </div>
        </div>
      )}
    </div>
  );
};
