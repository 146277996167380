import {useDisclosure} from '@mantine/hooks';
import Image from 'next/image';
import {useRouter} from 'next/router';
import {type FC, useEffect, useState} from 'react';
import {MdMail, MdMenu} from 'react-icons/md';
import LOGO_FULL from '../../../../../public/next-image/common/logo/full.svg';
import LOGO_SQUARE from '../../../../../public/next-image/common/logo/square.svg';
import {routes} from '../../../../config/constants';
import {useAuth} from '../../../../core/usecases/auth/AuthContext';
import {cn} from '../../../../lib/classname';
import {checkPathForPopup} from '../../../services/checkPathForPopup';
import {useBooleanState} from '../../../services/useBooleanState';
import {useMediaQuery} from '../../../services/useMediaQuery';
import {Popup} from '../Popup';
import {GuestHeaderBar} from './GuestHeaderBar';
import {LoginRegistButton} from './LoginRegistButton';
import {MenuItem} from './MenuItem';
import {SPDrawer} from './SPDrawer';
import {SearchBar} from './SearchBar';
import {UserMenu} from './UserMenu';

type Props = {
  className?: string;
  hasValidCookie: boolean;
  scoutsCount: number;
  interestsCount: number;
};

const menuItems = [
  {name: '募集を探す', url: routes.RECRUITINGS},
  {name: '企業を探す', url: routes.COMPANIES},
  {name: 'ES・体験記', url: routes.REPORTS},
  {name: '就活コラム', url: routes.COLUMNS},
  {name: 'コミュニティ', url: routes.QUESTIONS},
  {name: '相談室', url: routes.BOX_ANSWERS},
  {name: 'エンジニア', url: routes.ENGINEER},
  {name: 'AI選考対策', url: routes.REPORT_ANALYTICS_SEARCH, isUpdate: true},
  {name: 'AI ESビルダー', url: routes.ENGINEER_AI_ES_BUILDER, isUpdate: true},
  {
    name: 'イベント',
    url: 'https://event.gaishishukatsu.com/',
    target: '_blank',
  },
];

export const GeneralHeaderPresentation: FC<Props> = ({
  className,
  hasValidCookie,
  scoutsCount,
  interestsCount,
}) => {
  const {status, signOut, user} = useAuth(); // これはDecoratorでDI可能なので、プレゼンテーションに含めて良い
  const {isPC} = useMediaQuery();
  const [shownUserMenu, showUserMenu, hideUserMenu] = useBooleanState(false);
  const [openedSPMenu, {open, close}] = useDisclosure(false);
  const hasNotification = scoutsCount > 0 || interestsCount > 0;
  const {isLargeScreen} = useMediaQuery();
  const router = useRouter();
  const [searchQuery, setSearchQuery] = useState('');
  const [shownSearch, showSearch, hideSearch] = useBooleanState(false);
  const [shownReportMenu, showReportMenu, hideReportMenu] =
    useBooleanState(false);

  useEffect(() => {
    const queryParam: string =
      typeof router.query.q === 'string' ? router.query.q : '';
    setSearchQuery(queryParam);
  }, [router.query.q]);

  const handleSearchSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const encodedSearchQuery = encodeURIComponent(searchQuery);
    void router.push(`/search?q=${encodedSearchQuery}`);
  };

  const handleMouseEnter = (item: {
    name: string;
    url: string;
    target?: string;
  }) => {
    if (item.url === routes.REPORTS) showReportMenu();
  };

  const handleMouseLeave = (item: {
    name: string;
    url: string;
    target?: string;
  }) => {
    if (item.url === routes.REPORTS) hideReportMenu();
  };

  return (
    <div
      className={cn('bg-background', className)}
      role="banner"
      aria-label="header"
    >
      <div className={'fixed top-0 w-full z-50'}>
        <div className="hidden pc:block">
          {!hasValidCookie && <GuestHeaderBar />}
        </div>
        <div className="flex pc:px-12 pc:pb-8 pc:pt-24 bg-background-1 flex-col justify-center items-center">
          <div
            className={
              'flex w-full pc:max-w-[1200px] h-[68px] bg-white text-black-1  justify-center px-12 pc:px-24 pc:rounded-[24px] pc:shadow-header shadow-nav py-16'
            }
          >
            <div className="flex w-[1200px] self-stretch justify-between items-center text-sm">
              {!isLargeScreen && (
                <div className="flex items-center">
                  <button
                    onClick={open}
                    className="pc:hover:opacity-80 pc:hover:duration-300 pc:hover:ease-in-out"
                    aria-label="ドロワーメニューを開く"
                  >
                    <MdMenu size={24} />
                  </button>
                  <SPDrawer
                    opened={openedSPMenu}
                    onClose={close}
                    scoutsCount={scoutsCount}
                    interestsCount={interestsCount}
                  />
                </div>
              )}
              <a href={routes.HOME}>
                <Image
                  className="max-w-[80px]"
                  src={
                    isPC || status !== 'authenticated' ? LOGO_FULL : LOGO_SQUARE
                  }
                  alt="外資就活"
                />
              </a>
              {isLargeScreen && (
                <div className="flex justify-between items-center gap-20">
                  {menuItems.map((item) => (
                    <MenuItem
                      key={item.name}
                      item={item}
                      user={user}
                      handleMouseEnter={handleMouseEnter}
                      handleMouseLeave={handleMouseLeave}
                      shownReportMenu={shownReportMenu}
                    />
                  ))}
                </div>
              )}
              <div className="flex gap-8">
                {!shownSearch &&
                  (isLargeScreen ? (
                    <UserMenu
                      shownUserMenu={shownUserMenu}
                      showUserMenu={showUserMenu}
                      hideUserMenu={hideUserMenu}
                      scoutsCount={scoutsCount}
                      interestsCount={interestsCount}
                      isSignedIn={status === 'authenticated'}
                      hasNotification={hasNotification}
                      user={user}
                      signOut={signOut}
                    />
                  ) : (
                    status !== 'authenticated' && <LoginRegistButton />
                  ))}
                {isPC && (
                  <SearchBar
                    shownSearch={shownSearch}
                    showSearch={showSearch}
                    hideSearch={hideSearch}
                    handleSearchSubmit={handleSearchSubmit}
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                  />
                )}
                {status === 'authenticated' ? (
                  <div className="relative flex items-center">
                    <button
                      onClick={async () => router.push('/scouts')}
                      className="pc:hover:opacity-80 pc:hover:duration-300 pc:hover:ease-in-out"
                    >
                      <MdMail size={24} />
                    </button>
                    {scoutsCount > 0 && (
                      <div className="absolute right-0 top-0 flex h-8 w-8 rounded-full bg-notification" />
                    )}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          {user?.isDemoUser && (
            <div className="bg-accent-2 p-4 w-full">
              <p className="text-base text-black text-center">
                現在プレビュー閲覧用のアカウントでログインしています。プレビュー閲覧以外を行いたい場合は個人のアカウントでログインし直してください。
              </p>
            </div>
          )}
        </div>
      </div>
      {/* ヘッダー下のサイズ調整 */}
      <div
        className={`bg-background-1 ${status === 'authenticated' ? 'pc:pb-[86px] pb-[64px]' : 'pc:pb-[136px] pb-[56px]'}`}
      />
      {checkPathForPopup() && <Popup />}
    </div>
  );
};
