import type React from 'react';

const BUTTON_TYPE_DEFAULT = 'button';
const BUTTON_TYPE_SUBMIT = 'submit';
const BUTTON_TYPE_RESET = 'reset';
const ButtonType = {
  button: BUTTON_TYPE_DEFAULT,
  submit: BUTTON_TYPE_SUBMIT,
  reset: BUTTON_TYPE_RESET,
} as const;

type ButtonType = (typeof ButtonType)[keyof typeof ButtonType];

export type BasicButtonProps = {
  isLarge?: boolean;
} & BasicButtonThinProps;

export type BasicButtonThinProps = {
  label?: string;
  type?: ButtonType;
  className?: string;
  isPrimary?: boolean;
  isDisabled?: boolean;
  onClick?: () => void;
  children?: React.ReactNode;
};

const getBgClass = (isPrimary: boolean, isDisabled: boolean): string => {
  if (isDisabled) {
    return 'bg-disabled-1 pointer-events-none';
  }

  return isPrimary
    ? 'bg-accent-1 pc:hover:bg-accent-2'
    : 'bg-primary-1 pc:hover:bg-primary-2';
};

const getTextColorClass = (isDisabled: boolean): string => {
  return isDisabled ? 'text-black-4' : 'text-white';
};

export const BasicButton: React.FC<BasicButtonProps> = ({
  label,
  type = 'button',
  className = '',
  isPrimary = false,
  isLarge = false,
  isDisabled = false,
  onClick,
  children,
}) => {
  const bgClass: string = getBgClass(isPrimary, isDisabled);
  const textSizeClass: string = isLarge ? 'text-lg' : 'text';
  const textColorClass: string = getTextColorClass(isDisabled);
  const paddingClass: string = isLarge ? 'py-[9px]' : 'py-[10px]';
  return (
    <button
      type={type}
      className={`${className} ${bgClass} ${paddingClass} rounded-4 shadow-bottom pc:hover:ease-in-out pc:hover:duration-300 flex w-full min-w-[100px] items-center justify-between`}
      onClick={onClick}
    >
      <span
        className={`${textColorClass} ${textSizeClass} leading block w-full text-center font-bold`}
      >
        {children || label}
      </span>
    </button>
  );
};

export const BasicButtonThin: React.FC<BasicButtonThinProps> = ({
  label,
  type = 'button',
  className = '',
  isPrimary = false,
  isDisabled = false,
  onClick,
}) => {
  const bgClass: string = getBgClass(isPrimary, isDisabled);
  const textColorClass: string = getTextColorClass(isDisabled);
  return (
    <button
      type={type}
      className={`${className} ${bgClass} shadow-bottom rounded-4 pc:hover:ease-in-out pc:hover:duration-300 flex w-full min-w-[100px] items-center justify-between py-[7px]`}
      onClick={onClick}
    >
      <span
        className={`${textColorClass} leading block w-full text-center text-sm font-normal`}
      >
        {label}
      </span>
    </button>
  );
};
