import {atom, useRecoilState} from 'recoil';

const isSessionExpiredAtom = atom<boolean>({
  key: 'isSessionExpiredAtom',
  default: false,
});

export function useIsSessionExpired() {
  const [storedValue, setStoredValue] = useRecoilState(isSessionExpiredAtom);

  const updateStoredValue = (isSessionExpired: boolean) => {
    setStoredValue(isSessionExpired);
  };

  return {
    isSessionExpired: storedValue,
    updateIsSessionExpired: updateStoredValue,
  } as const;
}
