import {
  type AppError,
  InvalidTokenError,
  type Result,
  type ResultWithOauthToken,
} from '@packages/utils';
import {
  type UseQueryOptions,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import {parseCookies} from 'nookies';
import type {z} from 'zod';
import {DEFAULT_API_VERSION} from '../../config/apiVersion';
import {defaultClient} from '../../lib/defaultClient';
import {
  DeleteEntryFormResponseSchema,
  GetPopupMessageResponse,
  GetUserDataForAnalyticsResponse,
  GetUserMeInterestResponseSchema,
  GetUserMeScoutResponseSchema,
  GetUserProfileResponseSchema,
  GetUsersMeEntryFormResponseSchema,
  GetUsersMeEntryFormsResponseSchema,
  PutPopupMessageResponse,
  UsersMeFollowedCompaniesResponse,
  UsersMeResponse,
} from '../entities/usersMe/schema';
import {sendApiRequest} from '../usecases/auth/sendApiRequest';
import {useIsSessionExpired} from '../usecases/auth/useIsSessionExpired';

type ResultGetUserMeInterestUnreadCount = ResultWithOauthToken<
  GetUserMeInterestUnreadCountResponse,
  AppError
>;

type UseUserMeInterestUnreadCountArg = {
  config?: UseQueryOptions<ResultGetUserMeInterestUnreadCount>;
  version?: string;
};

type ResultGetUserMeScoutUnreadCount = ResultWithOauthToken<
  GetUserMeScoutUnreadCountResponse,
  AppError
>;

type UseUserMeScoutUnreadCountArg = {
  config?: UseQueryOptions<ResultGetUserMeScoutUnreadCount>;
  version?: string;
};

type ResultGetUserProfileInputRate = ResultWithOauthToken<
  GetUserProfileIputRateResponse,
  AppError
>;

type UseUserProfileInputRateArg = {
  config?: UseQueryOptions<ResultGetUserProfileInputRate>;
  version?: string;
};

export type GetUserDataForAnalyticsResponse = z.infer<
  typeof GetUserDataForAnalyticsResponse
>;

type UserDataForAnalyticsArgs = {
  cookies: Partial<Record<string, string>>;
  version?: string;
};

export const getUserDataForAnalytics = async ({
  cookies,
  version = DEFAULT_API_VERSION,
}: UserDataForAnalyticsArgs) => {
  const request = async (accessToken: string) => {
    defaultClient.setToken(accessToken);
    return defaultClient.get(
      `/${version}/users/me/analytics`,
      GetUserDataForAnalyticsResponse,
    );
  };

  const result = await sendApiRequest({
    cookies,
    authRequired: true,
    request,
    version,
  });

  if (!result?.ok) {
    throw new Error(result.error);
  }

  return result;
};

export type GetUserMeInterestUnreadCountResponse = z.infer<
  typeof GetUserMeInterestResponseSchema
>;

export type GetUserMeInterestUnreadCountArg = {
  cookies: Partial<Record<string, string>>;
  version?: string;
};

export const getUserMeInterestUnreadCount = async ({
  cookies,
  version = DEFAULT_API_VERSION,
}: GetUserMeInterestUnreadCountArg) => {
  const request = async (accessToken: string) => {
    defaultClient.setToken(accessToken);
    return defaultClient.get(
      `/${version}/users/me/interests/unread-count`,
      GetUserMeInterestResponseSchema,
    );
  };

  const response = await sendApiRequest({
    request,
    cookies,
    version,
  });
  return response;
};

export type GetUserMeScoutUnreadCountResponse = z.infer<
  typeof GetUserMeScoutResponseSchema
>;

export type GetUserMeScoutUnreadCountArg = {
  cookies: Partial<Record<string, string>>;
  version?: string;
};

export const getUserMeScoutUnreadCount = async ({
  cookies,
  version = DEFAULT_API_VERSION,
}: GetUserMeScoutUnreadCountArg) => {
  const request = async (accessToken: string) => {
    defaultClient.setToken(accessToken);
    return defaultClient.get(
      `/${version}/users/me/scout-messages/unread-count`,
      GetUserMeScoutResponseSchema,
    );
  };

  const response = await sendApiRequest({
    request,
    cookies,
    version,
  });

  return response;
};

export type GetUserProfileIputRateResponse = z.infer<
  typeof GetUserProfileResponseSchema
>;

export type GetUserProfileIputRateArg = {
  cookies: Partial<Record<string, string>>;
  version?: string;
};

export const getUserProfileInputRate = async ({
  cookies,
  version = DEFAULT_API_VERSION,
}: GetUserProfileIputRateArg) => {
  const request = async (accessToken: string) => {
    defaultClient.setToken(accessToken);
    return defaultClient.get(
      `/${version}/users/me/profile_input_rate`,
      GetUserProfileResponseSchema,
    );
  };

  const response = await sendApiRequest({
    request,
    cookies,
    version,
  });

  return response;
};

export type UsersMeResponse = z.infer<typeof UsersMeResponse>;

type UsersMeReturnValueType = Result<UsersMeResponse, AppError>;

type GetUsersMeArgs = {
  cookies: Partial<Record<string, string>>;
  version?: string;
};

type UseUsersMeOptions = {
  config?: UseQueryOptions<UsersMeReturnValueType>;
  version?: string;
};

export const getUsersMe = async ({
  cookies,
  version = DEFAULT_API_VERSION,
}: GetUsersMeArgs) => {
  const request = async (accessToken: string) => {
    defaultClient.setToken(accessToken);
    return defaultClient.get(`/${version}/users/me`, UsersMeResponse);
  };

  const result = await sendApiRequest({
    cookies,
    authRequired: true,
    request,
    version,
  });

  return result;
};

export type UsersMeFollowedCompaniesResponse = z.infer<
  typeof UsersMeFollowedCompaniesResponse
>;

type UsersMeFollowedCompaniesArgs = {
  cookies: Partial<Record<string, string>>;
  version?: string;
};

export const getUserFollowedCompanies = async ({
  cookies,
  version = DEFAULT_API_VERSION,
}: UsersMeFollowedCompaniesArgs) => {
  const request = async (accessToken: string) => {
    defaultClient.setToken(accessToken);
    return defaultClient.get(
      `/${version}/users/me/followed-companies`,
      UsersMeFollowedCompaniesResponse,
    );
  };

  const result = await sendApiRequest({
    request,
    cookies,
    version,
  });

  return result;
};

export const useUserMeInterestUnreadCount = ({
  config,
  ...args
}: UseUserMeInterestUnreadCountArg) => {
  const {updateIsSessionExpired} = useIsSessionExpired();
  const {data, isLoading} = useQuery({
    enabled: config?.enabled ?? true,
    queryKey: ['useUserMeInterestUnreadCount'],
    async queryFn() {
      const cookies = parseCookies();
      const result = await getUserMeInterestUnreadCount({...args, cookies});
      if (!result.ok && result.error === InvalidTokenError) {
        updateIsSessionExpired(true);
      }

      return result;
    },
  });

  const count = data?.ok ? data.value.count : undefined;

  return {
    count,
    isLoading,
  };
};

export const useUserMeScoutUnreadCount = ({
  config,
  ...args
}: UseUserMeScoutUnreadCountArg) => {
  const {updateIsSessionExpired} = useIsSessionExpired();
  const {data, isLoading} = useQuery<ResultGetUserMeScoutUnreadCount>(
    ['useUserMeScoutUnreadCount'],
    async () => {
      const cookies = parseCookies();
      const result = await getUserMeScoutUnreadCount({...args, cookies});
      if (!result.ok && result.error === InvalidTokenError) {
        updateIsSessionExpired(true);
      }

      return result;
    },
    config,
  );

  const count = data?.ok ? data.value.count : undefined;

  return {
    count,
    isLoading,
  };
};

export const useUserProfileInputRate = ({
  config,
  ...args
}: UseUserProfileInputRateArg) => {
  const {updateIsSessionExpired} = useIsSessionExpired();
  const {data, isLoading} = useQuery<ResultGetUserProfileInputRate>(
    ['useUserProfileInputRate'],
    async () => {
      const cookies = parseCookies();
      const result = await getUserProfileInputRate({
        ...args,
        cookies,
      });
      if (!result.ok && result.error === InvalidTokenError) {
        updateIsSessionExpired(true);
      }

      return result;
    },
    config,
  );

  return {
    profileInputRate: data,
    isLoading,
  };
};

export const useUsersMe = ({config, version}: UseUsersMeOptions) => {
  const {updateIsSessionExpired} = useIsSessionExpired();
  return useQuery<UsersMeReturnValueType>({
    ...config,
    queryKey: ['usersMe'],
    async queryFn() {
      const cookies = parseCookies();
      const result = await getUsersMe({cookies, version});

      if (!result.ok && result.error === InvalidTokenError) {
        updateIsSessionExpired(true);
      }

      return result;
    },
  });
};

// ポッアップメッセージ取得
export type GetPopupMessageResponse = z.infer<typeof GetPopupMessageResponse>;
export type GetPopupMessageReturnValueType = Result<GetPopupMessageResponse>;

export type GetPopupMessageArgs = {
  version?: string;
  cookies: Partial<Record<string, string>>;
};

type ResultGetPopupMessage = ResultWithOauthToken<
  GetPopupMessageResponse,
  AppError
>;

export const getPopupMessage = async ({
  version = DEFAULT_API_VERSION,
  cookies,
}: GetPopupMessageArgs) => {
  const request = async (accessToken: string) => {
    defaultClient.setToken(accessToken);
    const path = `/${version}/users/me/popup-message`;
    return defaultClient.get(path, GetPopupMessageResponse);
  };

  const result = await sendApiRequest({
    request,
    cookies,
    version,
  });

  return result;
};

type GetPopupMessageOptions = {
  config?: UseQueryOptions<ResultGetPopupMessage>;
  version?: string;
};

export const useGetPopupMessage = ({
  config,
  version,
}: GetPopupMessageOptions) => {
  const {updateIsSessionExpired} = useIsSessionExpired();
  return useQuery<ResultGetPopupMessage>({
    ...config,
    queryKey: ['GetPopupMessage'],
    async queryFn() {
      const cookies = parseCookies();
      const result = await getPopupMessage({cookies, version});
      if (!result.ok && result.error === InvalidTokenError) {
        updateIsSessionExpired(true);
      }

      return result;
    },
  });
};

export type PutPopupMessageResponse = z.infer<typeof PutPopupMessageResponse>;
export type PutPopupMessageReturnValueType = Result<PutPopupMessageResponse>;
export type PutPopupMessageDataType = string;
type PutPopupMessageType = {
  onSuccessFnc: () => void;
  onErrorFnc: (error: unknown) => void;
};

export type PutPopupMessageArgs = {
  version?: string;
  cookies: Partial<Record<string, string>>;
  messageType: string;
};

export const putPopupMessage = async ({
  version = DEFAULT_API_VERSION,
  cookies,
  messageType,
}: PutPopupMessageArgs) => {
  const request = async (accessToken: string) => {
    defaultClient.setToken(accessToken);
    const path = `/${version}/users/me/close-popup`;
    return defaultClient.put(path, PutPopupMessageResponse, {
      messageType,
    });
  };

  const result = await sendApiRequest({
    request,
    cookies,
    version,
  });

  return result;
};

export const usePutPopupMessage = ({
  onSuccessFnc,
  onErrorFnc,
}: PutPopupMessageType) => {
  const {updateIsSessionExpired} = useIsSessionExpired();
  return useMutation(
    async (messageType: string) => {
      const cookies = parseCookies();
      const result = await putPopupMessage({cookies, messageType});
      if (!result.ok && result.error === InvalidTokenError) {
        updateIsSessionExpired(true);
      }

      return result;
    },
    {
      onSuccess() {
        onSuccessFnc();
      },
      onError(error) {
        onErrorFnc(error);
      },
    },
  );
};

/**
 * GetUsersMeEntryForm
 */

export type GetUsersMeEntryFormResponse = z.infer<
  typeof GetUsersMeEntryFormResponseSchema
>;

export type GetUsersMeEntryFormReturnValueType = Result<
  GetUsersMeEntryFormResponse,
  AppError
>;

type GetUsersMeEntryFormArgs = {
  cookies: Partial<Record<string, string>>;
  version?: string;
  recruitmentId: string;
};

export const getUsersMeEntryForm = async ({
  cookies,
  version = DEFAULT_API_VERSION,
  recruitmentId,
}: GetUsersMeEntryFormArgs) => {
  const request = async (accessToken: string) => {
    defaultClient.setToken(accessToken);
    const path = `/${version}/users/me/entries/${recruitmentId}`;
    return defaultClient.get(path, GetUsersMeEntryFormResponseSchema);
  };

  const result = await sendApiRequest({
    request,
    cookies,
    version,
  });

  return result;
};

type UseGetUsersMeEntryFormArgs = {
  version?: string;
  recruitmentId: string;
};

export const useUsersMeEntryForm = ({
  recruitmentId,
}: UseGetUsersMeEntryFormArgs) => {
  const {updateIsSessionExpired} = useIsSessionExpired();
  return useQuery<GetUsersMeEntryFormReturnValueType>({
    queryKey: ['getUsersMeEntryForm', recruitmentId],
    async queryFn() {
      const cookies = parseCookies();
      const result = await getUsersMeEntryForm({cookies, recruitmentId});
      if (!result.ok && result.error === InvalidTokenError) {
        updateIsSessionExpired(true);
      }

      return result;
    },
  });
};

/**
 * GetUsersMeEntryForms
 */

export type GetUsersMeEntryFormsResponse = z.infer<
  typeof GetUsersMeEntryFormsResponseSchema
>;

export type GetUsersMeEntryFormsReturnValueType = Result<
  GetUsersMeEntryFormsResponse,
  AppError
>;

type GetUsersMeEntryFormsArgs = {
  cookies: Partial<Record<string, string>>;
  version?: string;
};

export const getUsersMeEntryForms = async ({
  cookies,
  version = DEFAULT_API_VERSION,
}: GetUsersMeEntryFormsArgs) => {
  const request = async (accessToken: string) => {
    defaultClient.setToken(accessToken);
    return defaultClient.get(
      `/${version}/users/me/entries`,
      GetUsersMeEntryFormsResponseSchema,
    );
  };

  const result = await sendApiRequest({
    request,
    cookies,
    version,
  });

  return result;
};

type UseGetUsersMeEntryFormsArgs = {
  version?: string;
};

export const useGetUsersMeEntryForms = ({
  version,
}: UseGetUsersMeEntryFormsArgs) => {
  const {updateIsSessionExpired} = useIsSessionExpired();
  return useQuery<GetUsersMeEntryFormsReturnValueType>({
    queryKey: ['getUsersMeEntryForms'],
    async queryFn() {
      const cookies = parseCookies();
      const result = await getUsersMeEntryForms({cookies, version});
      if (!result.ok && result.error === InvalidTokenError) {
        updateIsSessionExpired(true);
      }

      return result;
    },
  });
};

/**
 * DeleteUsersMeEntryForm
 */

export type DeleteEntryFormRespons = z.infer<
  typeof DeleteEntryFormResponseSchema
>;

export type DeleteEntryFormReturnValueType = Result<
  DeleteEntryFormRespons,
  AppError
>;

type DeleteEntryFormArgs = {
  cookies: Partial<Record<string, string>>;
  version?: string;
  recruitmentId: string;
};

type DeleteEntryFormOptions = {
  recruitmentId: string;
  onSuccessFnc: () => void;
  onErrorFnc: (error: AppError) => void;
};

export const deleteEntryForm = async ({
  cookies,
  version = DEFAULT_API_VERSION,
  recruitmentId,
}: DeleteEntryFormArgs) => {
  const request = async (accessToken: string) => {
    defaultClient.setToken(accessToken);
    return defaultClient.delete(
      `/${version}/users/me/entries/${recruitmentId}`,
      DeleteEntryFormResponseSchema,
    );
  };

  const result = await sendApiRequest({
    request,
    cookies,
    version,
  });

  return result;
};

export const useDeleteEntryForm = ({
  recruitmentId,
  onSuccessFnc,
  onErrorFnc,
}: DeleteEntryFormOptions) => {
  const {updateIsSessionExpired} = useIsSessionExpired();
  return useMutation(
    async () => {
      const cookies = parseCookies();
      const result = await deleteEntryForm({cookies, recruitmentId});
      if (!result.ok && result.error === InvalidTokenError) {
        updateIsSessionExpired(true);
      }

      return result;
    },
    {
      onSuccess() {
        onSuccessFnc();
      },
      onError(error) {
        onErrorFnc(error as AppError);
      },
    },
  );
};
