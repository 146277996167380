import {createContext, useContext} from 'react';
import type {UsersMeResponse} from '../../repositories/usersMeRepository';

export type UserType = UsersMeResponse;

/**
 * 認証状態を表す型
 * @description
 * - 有効なトークンがCookieに存在 -> ユーザー情報を取得中 -> loading
 * - ユーザー情報を取得できた -> authenticated
 * - ユーザー情報を取得できなかった -> error
 * - 有効なトークンがCookieに存在しない -> unauthenticated
 *
 * ここでいう「有効なトークン」とは、Cookieに所定のキーが存在することを指し、
 * 検証済みであることを示すものではない！
 *
 * 旧`useAuth`との対応
 * - isIdle <-> status === 'loading'
 * - isSignedIn <-> status === 'authenticated'
 */
export type AuthStatus =
  | 'loading'
  | 'authenticated'
  | 'unauthenticated'
  | 'error';
type BaseAuthState = {
  user: UserType | undefined;
  accessToken: string | undefined;
  signOut: () => Promise<void>;
  status: AuthStatus;
};
type Loading = BaseAuthState & {
  user: undefined;
  accessToken: string;
  status: 'loading';
};
type Authenticated = BaseAuthState & {
  user: UserType;
  accessToken: string;
  status: 'authenticated';
};
type Unauthenticated = BaseAuthState & {
  user: undefined;
  accessToken: undefined;
  status: 'unauthenticated';
};
type ErrorState = BaseAuthState & {
  user: undefined;
  accessToken: string;
  status: 'error';
};
export type AuthState = Loading | Authenticated | Unauthenticated | ErrorState;

export const mockSignOut = async () => {
  console.log('signOut() called but not implemented');
};

export const defaultAuthState: AuthState = {
  user: undefined,
  accessToken: undefined,
  signOut: mockSignOut,
  status: 'unauthenticated',
};

export const AuthContext = createContext<AuthState>(defaultAuthState);

export const useAuth = () => useContext(AuthContext);
