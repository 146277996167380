import {Box, Text} from '@mantine/core';
import type React from 'react';
import {MdClose} from 'react-icons/md';
import {PROFILE_BASE} from '../../../config/constants/routes';
import {useMediaQuery} from '../../../shared/services/useMediaQuery';

type Props = {
  closePopup: () => void;
};

export const ProfilePromo: React.FC<Props> = ({closePopup}) => {
  const handlePressButton = () => {
    location.href = PROFILE_BASE;
  };

  const {isPC} = useMediaQuery();
  return (
    <>
      {isPC ? (
        <Box className="flex flex-row justify-between items-center">
          <Box className="flex flex-row text-base text-black">
            「プロフィールを編集」から、あなたの志向や経験を充実させると、厳選した優良企業からスカウトが届きやすくなります。
          </Box>
          <Box className="flex flex-row items-center gap-12">
            <Box
              className="cursor-pointer flex items-center justify-center border-primary border-[1px] bg-white rounded-[8px] py-8 px-12"
              onClick={handlePressButton}
            >
              <Text className="text-sm text-primary font-bold">
                プロフィール編集へ
              </Text>
            </Box>
            <Box
              className="w-24 h-24 flex items-center justify-center rounded-full bg-black-3 cursor-pointer"
              onClick={closePopup}
            >
              <MdClose size={24} color="white" />
            </Box>
          </Box>
        </Box>
      ) : (
        <Box
          className="flex flex-row justify-between text-sm"
          onClick={handlePressButton}
        >
          <Text>
            「プロフィールを編集」から、あなたの志向や経験を充実させると、厳選した優良企業からスカウトが届きやすくなります。
          </Text>
          <Box
            className="flex flex-row text-primary-1 items-center"
            onClick={(e) => {
              e.stopPropagation();
              closePopup();
            }}
          >
            <MdClose size={16} className="text-black-3" />
          </Box>
        </Box>
      )}
    </>
  );
};
