import Link from 'next/link';
import type {ComponentPropsWithoutRef, FC} from 'react';
import isAppWebView from '../../config/isAppWebView';

type NextLink = ComponentPropsWithoutRef<typeof Link>;
type AnchorLink = ComponentPropsWithoutRef<'a'>;

/**
 * Nextjsと普通のaタグで競合する型はhrefのみ \
 * `scroll`など、Nextjs固有の型も存在するが、\
 * それが仮にaタグに渡されたとしても無視されるだけ
 */
type CommonLinkProps = Omit<NextLink, 'href'> &
  Omit<AnchorLink, 'href'> & {
    href: string;
  };

export const CommonLink: FC<CommonLinkProps> = ({href, children, ...props}) => {
  if (isAppWebView()) {
    return (
      <a href={href} {...props}>
        {children}
      </a>
    );
  }

  return (
    <Link href={href} {...props}>
      {children}
    </Link>
  );
};
