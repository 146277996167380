import {
  type DefaultOptions,
  QueryClient,
  type UseMutationOptions,
  UseQueryOptions,
} from '@tanstack/react-query';
import type {AxiosError} from 'axios';

// See:
// https://github.com/alan2207/bulletproof-react/blob/master/src/lib/react-query.ts

const queryConfig: DefaultOptions = {
  queries: {
    useErrorBoundary: false,
    refetchOnWindowFocus: false,
    retry: false,
  },
};

export const queryClient = new QueryClient({defaultOptions: queryConfig});

export type ExtractFnReturnType<FnType extends (...args: any) => any> = Awaited<
  ReturnType<FnType>
>;

export type MutationConfig<MutationFnType extends (...args: any) => any> =
  UseMutationOptions<
    ExtractFnReturnType<MutationFnType>,
    AxiosError,
    Parameters<MutationFnType>[0]
  >;
