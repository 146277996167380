import {Drawer} from '@mantine/core';
import type {FC} from 'react';
import {FaExternalLinkAlt} from 'react-icons/fa';
import {MdAccountCircle, MdOutlineFiberNew} from 'react-icons/md';
import {useAuthAndCounts} from '../../../../core/usecases/auth/useAuthAndCounts';
import {overlayConfig} from '../../modalConfig';
import {HeaderMenu} from '../Menu';

type Props = {
  opened: boolean;
  onClose: () => void;
  scoutsCount: number;
  interestsCount: number;
};

const spMenuItems: Array<{
  name: string;
  url: string;
  isNew?: boolean;
  isUpdate?: boolean;
}> = [
  {
    name: 'AI ESビルダー',
    url: '/engineer/ai-es-builder',
    isUpdate: true,
  },
  {
    name: 'AI選考対策',
    url: '/report_analytics/search',
    isUpdate: true,
  },
  {
    name: 'Software Engineer 就活',
    url: '/engineer',
  },
  {
    name: '募集を探す',
    url: '/recruiting_info',
  },
  {
    name: '企業を探す',
    url: '/company',
  },
  {
    name: 'ES・体験記',
    url: '/reports',
  },
  {
    name: '就活コラム',
    url: '/column/categories',
  },
  {
    name: 'コミュニティ',
    url: '/questions',
  },
  {
    name: '相談室',
    url: '/box/answers',
  },
  {
    name: '各種設定・その他',
    url: '/settings',
  },
];

export const SPDrawer: FC<Props> = ({
  opened,
  onClose: close,
  scoutsCount,
  interestsCount,
}) => {
  const {user, signOut, status} = useAuthAndCounts();
  return (
    <Drawer
      position="left"
      withCloseButton={false}
      opened={opened}
      onClose={() => {
        close();
      }}
      padding={0}
      size="70%"
      className="top-52"
      {...overlayConfig}
    >
      <div className="h-full p-16 text-black-1 bg-background-2">
        <div className="overflow-hidden rounded-12 bg-white-1 p-12">
          {user && (
            <div className="flex gap-8 px-12 pb-4 pt-12">
              <MdAccountCircle size={24} />
              <div className="font-bold">{user.familyName}さん</div>
            </div>
          )}
          <HeaderMenu
            showMyPage={status === 'authenticated'}
            scoutsCount={scoutsCount}
            interestsCount={interestsCount}
            onLogout={signOut}
            className="w-full bg-white-1"
            onItemClick={close}
          />
        </div>
        <ul className="mt-12 flex flex-col divide-y divide-line">
          {spMenuItems.map((item) => {
            return (
              <li key={item.name} className="p-12">
                <a
                  href={
                    // エンジニア学生にはデフォルト「エンジニア志望向け」にチェックが入っている状態で遷移させる
                    item.url === '/recruiting_info' && user?.isEngineer
                      ? `${item.url}?label_ids=3`
                      : item.url
                  }
                  className="flex items-center font-bold leading"
                >
                  {item.isNew && <MdOutlineFiberNew size={36} />}
                  {item.isUpdate && <UpdateIcon />}
                  <span className="ml-8">{item.name}</span>
                </a>
              </li>
            );
          })}
          {status !== 'authenticated' && (
            <li className="p-12">
              <a
                href="https://go.gaishishukatsu.com/biz"
                className="flex items-center font-bold leading"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="ml-8">採用担当者様はこちら</span>
                <span>
                  <FaExternalLinkAlt />
                </span>
              </a>
            </li>
          )}
        </ul>
      </div>
    </Drawer>
  );
};

const UpdateIcon: FC = () => {
  return (
    <div className="font-bold rounded-12 text-center text-[8px] h-12 mr-4 w-32">
      UPDATE
    </div>
  );
};
