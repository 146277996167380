import type {FC} from 'react';
import {MobileFooter} from './MobileFooter';
import {PcFooter} from './PcFooter';

type Props = {
  className?: string;
};

export const GeneralFooter: FC<Props> = ({className}) => {
  return (
    <div className={className}>
      <div className="pc:hidden">
        <MobileFooter />
      </div>
      <div className="hidden pc:block">
        <PcFooter />
      </div>
    </div>
  );
};
