import {Box, Modal} from '@mantine/core';
import {useRouter} from 'next/router';
import {type FC, useEffect} from 'react';
import {routes} from '../../../config/constants';
import isAppWebView from '../../../config/isAppWebView';
import {QUERY_PARAMS_FOR_REDIRECT_FROM_LOGIN_PAGE} from '../../../core/entities/login/constants';
import {destroyCookieWithLogout} from '../../../core/usecases/auth/destroyCookieWithLogout';
import {redirectToLoginOnSessionExpiration} from '../../../core/usecases/auth/redirectToLoginOnTokenExpiraration';
import {useIsSessionExpired} from '../../../core/usecases/auth/useIsSessionExpired';
import {CommonLink} from '../CommonLink';

export const SessionExpiredPopup: FC = () => {
  const {isSessionExpired, updateIsSessionExpired} = useIsSessionExpired();
  const router = useRouter();

  // ユーザーに操作させないので空関数
  const onClose = () => {};

  useEffect(() => {
    if (isSessionExpired) {
      destroyCookieWithLogout();

      if (
        isAppWebView() &&
        typeof window !== 'undefined' &&
        (window as any).ReactNativeWebView
      ) {
        (window as any).ReactNativeWebView.postMessage(
          JSON.stringify({redirectToLoginOnSessionExpiration: true}),
        );
      } else {
        setTimeout(async () => {
          await redirectToLoginOnSessionExpiration(router.asPath);
          updateIsSessionExpired(false);
        }, 1500);
      }
    }
  }, [isSessionExpired]);

  if (!isSessionExpired || isAppWebView()) {
    return null;
  }

  return (
    <>
      <Modal
        opened={true}
        onClose={onClose}
        withCloseButton={false}
        overlayProps={{opacity: 0.48}}
        centered
      >
        <Box className="flex flex-col items-center p-12 pc:p-16 text-base text-black-1 leading">
          <p>セッションが切れました</p>
          <p className="mb-12">再ログインをお願いします</p>
          <CommonLink
            href={`${routes.LOGIN}?${QUERY_PARAMS_FOR_REDIRECT_FROM_LOGIN_PAGE}=${router.asPath}`}
            className="text-link-1 underline hover:opacity-80"
          >
            ログイン画面へ
          </CommonLink>
        </Box>
      </Modal>
    </>
  );
};
