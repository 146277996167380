import {
  useUserMeInterestUnreadCount,
  useUserMeScoutUnreadCount,
} from '../../repositories/usersMeRepository';
import {useAuth} from './AuthContext';

export const useAuthAndCounts = () => {
  // MEMO: アクセストークンなどの認証情報を取得
  const auth = useAuth();

  const {count: scoutsCount} = useUserMeScoutUnreadCount({
    config: {enabled: auth.status === 'authenticated'},
  });
  const {count: interestsCount} = useUserMeInterestUnreadCount({
    config: {enabled: auth.status === 'authenticated'},
  });

  return {
    scoutsCount: scoutsCount ?? 0,
    interestsCount: interestsCount ?? 0,
    ...auth,
  };
};
