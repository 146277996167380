import {useRouter} from 'next/router';
import {parseCookies} from 'nookies';
import type React from 'react';
import {type ReactNode, useCallback, useEffect, useState} from 'react';
import {HOME} from '../../../config/constants/routes';
import {logout} from '../../repositories/logoutRepository';
import {useUsersMe} from '../../repositories/usersMeRepository';
import {AuthContext, type AuthState, defaultAuthState} from './AuthContext';
import {validateCookie} from './checkOauthTokenExistence';
import {destroyCookieWithLogout} from './destroyCookieWithLogout';

type Props = {
  children: ReactNode;
  override?: AuthState;
};

export const AuthProvider: React.FC<Props> = ({children, override}) => {
  const cookies = parseCookies();
  const {isValid, accessToken} = validateCookie(cookies);
  const router = useRouter();
  const signOut = useCallback(async () => {
    const cookies = parseCookies();
    await logout({cookies});
    destroyCookieWithLogout();

    if (router.pathname === HOME) {
      router.reload(); // トップページでログアウトした場合表示が更新されないためreloadする
      return;
    }

    await router.push(HOME);
  }, []);

  const [authState, setAuthState] = useState<AuthState>(
    override || isValid
      ? {
          status: 'loading',
          accessToken,
          user: undefined,
          signOut,
        }
      : defaultAuthState,
  );

  useUsersMe({
    config: {
      enabled: isValid,
      onSuccess(data) {
        if (data.ok) {
          setAuthState(
            override || {
              status: 'authenticated',
              accessToken,
              user: data.value,
              signOut,
            },
          );
        }
      },
      onError() {
        setAuthState(
          override || {
            status: 'error',
            accessToken,
            user: undefined,
            signOut,
          },
        );
      },
    },
  });

  useEffect(() => {
    if (override) {
      setAuthState(override);
    }
  }, [override, isValid]);

  return (
    <AuthContext.Provider value={authState}>{children}</AuthContext.Provider>
  );
};
