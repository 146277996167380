import type * as React from 'react';
import {MdRadioButtonChecked, MdRadioButtonUnchecked} from 'react-icons/md';

export type RadioButtonProps = {
  isSelected: boolean;
};

export const RadioButton: React.FC<RadioButtonProps> = ({
  isSelected = false,
}) => {
  return isSelected ? (
    <div className="mr-8">
      <MdRadioButtonChecked fill="#1E83B9" size={20} />
    </div>
  ) : (
    <div className="mr-8">
      <MdRadioButtonUnchecked fill="#1E83B9" size={20} />
    </div>
  );
};
