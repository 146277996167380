import Image from 'next/image';
import type {FC} from 'react';
import {
  FaExternalLinkAlt,
  FaInstagram,
  FaTiktok,
  FaYoutube,
} from 'react-icons/fa';
import {FaXTwitter} from 'react-icons/fa6';
import APP_STORE_ANDROID_IMAGE from '../../../../../public/next-image/common/appStore/android.png';
import APP_STORE_IOS_IMAGE from '../../../../../public/next-image/common/appStore/ios.png';
import LOGO_SQUARE_WHITE from '../../../../../public/next-image/common/logo/square_white.svg';
import {
  GSSKT_INSTAGRAM_URL,
  GSSKT_TIKTOK_URL,
  GSSKT_TWITTER_URL,
  GSSKT_YOUTUBE_URL,
} from '../../../../config/constants/hosts';
import {useAuth} from '../../../../core/usecases/auth/AuthContext';
import {
  footerMenuItems,
  footerMenuItems2,
  gssktAppLink,
  searchCompaniesFromIndustryItems,
  searchReportsFromDepartmentItems,
  searchReportsFromIndustryItems,
} from '../items';

type Props = {
  className?: string;
};

export const PcFooter: FC<Props> = ({className}) => {
  const {user} = useAuth();

  return (
    <div className={className}>
      <div className="h-[508px] flex justify-center items-start gap-24 p-64 bg-background-footer">
        <div className="flex-1 flex flex-col h-full items-start justify-between">
          <div className="flex flex-col gap-12">
            <div className="pb-8">
              <Image
                src={LOGO_SQUARE_WHITE}
                alt="外資就活"
                className="w-full h-[100px]"
              />
            </div>
            <div className="flex justify-between text-white">
              <a href={GSSKT_TWITTER_URL} target="_blank" rel="noreferrer">
                <FaXTwitter />
              </a>
              <a href={GSSKT_INSTAGRAM_URL} target="_blank" rel="noreferrer">
                <FaInstagram />
              </a>
              <a href={GSSKT_YOUTUBE_URL} target="_blank" rel="noreferrer">
                <FaYoutube />
              </a>
              <a href={GSSKT_TIKTOK_URL} target="_blank" rel="noreferrer">
                <FaTiktok />
              </a>
            </div>
          </div>
          <div className="flex flex-col gap-8">
            <div className="flex w-full gap-8">
              <a href={gssktAppLink.ios}>
                <Image
                  src={APP_STORE_IOS_IMAGE}
                  alt="Download on the App Store"
                />
              </a>
              <a href={gssktAppLink.android}>
                <Image
                  src={APP_STORE_ANDROID_IMAGE}
                  alt="GET IT ON Google Play"
                />
              </a>
            </div>
            <div className="text-xs text-white">
              © Howtelevision,Inc. All Rights Reserved.
            </div>
          </div>
        </div>
        <div className="flex-1 flex h-full items-start">
          <ul className="flex flex-col text-sm text-white font-bold gap-8">
            {footerMenuItems.map((item) => (
              <li key={item.label}>
                <a
                  href={
                    // エンジニア学生にはデフォルト「エンジニア志望向け」にチェックが入っている状態で遷移させる
                    item.href === '/recruiting_info' && user?.isEngineer
                      ? `${item.href}?tagIDs=3`
                      : item.href
                  }
                  className="block"
                  target={item.target}
                >
                  {`${item.label} `}
                  {item.target && (
                    <span className="inline-flex">
                      <FaExternalLinkAlt />
                    </span>
                  )}
                </a>
              </li>
            ))}
          </ul>
        </div>
        <div className="flex-1 flex flex-col h-full items-start">
          <div className="text-white">
            <div className="font-bold text-sm pb-8">企業を探す</div>
            <ul className="flex flex-col gap-8 text-sm">
              {searchCompaniesFromIndustryItems.map((item) => (
                <li key={item.label}>
                  <a href={item.href}>{item.label}</a>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="flex-1 flex flex-col h-full items-start">
          <div className="text-white">
            <div className="font-bold text-sm pb-8">業界のES・体験記</div>
            <ul className="flex flex-col gap-8 text-sm">
              {searchReportsFromIndustryItems.map((item) => (
                <li key={item.label}>
                  <a href={item.href}>{item.label}</a>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="flex-1 flex flex-col h-full items-start">
          <div className="text-white">
            <div className="font-bold text-sm pb-8">
              部門・職種別のES・体験記
            </div>
            <ul className="flex flex-col gap-8 text-sm">
              {searchReportsFromDepartmentItems.map((item) => (
                <li key={item.label}>
                  <a href={item.href}>{item.label}</a>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="flex-1 flex flex-col h-full items-start">
          <div className="text-white">
            <ul className="flex flex-col gap-8 text-sm">
              {footerMenuItems2.map((item) => {
                const {label, ...itemProps} = item;

                return (
                  <li key={item.label} className="px-8">
                    <a {...itemProps}>{label}</a>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
