import {setCookie} from 'nookies';
import {
  ACCESS_TOKEN_MAX_AGE,
  REFRESH_TOKEN_MAX_AGE,
  cookieApiRefreshTokenKey,
  cookieApiTokenKey,
} from '../../../server/token';

type AccessTokenProps = {
  accessToken: string;
};

export function setAccessTokenToCookie({accessToken}: AccessTokenProps): void {
  setCookie(null, cookieApiTokenKey, accessToken, {
    maxAge: ACCESS_TOKEN_MAX_AGE,
    path: '/',
  });
}

type RefreshTokenProps = {
  refreshToken: string;
};
export function setRefreshTokenToCookie({
  refreshToken,
}: RefreshTokenProps): void {
  setCookie(null, cookieApiRefreshTokenKey, refreshToken, {
    maxAge: REFRESH_TOKEN_MAX_AGE,
    path: '/',
  });
}
