import {Box, Text} from '@mantine/core';
import type React from 'react';
import {MdClose} from 'react-icons/md';
import {LP_TO_LIIGA_ACCOUNT} from '../../../config/constants/routes';
import {useMediaQuery} from '../../../shared/services/useMediaQuery';

type Props = {
  closePopup: () => void;
};
export const OffereePromo: React.FC<Props> = ({closePopup}) => {
  const handleToLiiga = () => {
    location.href = LP_TO_LIIGA_ACCOUNT;
  };

  const {isPC} = useMediaQuery();
  return (
    <>
      {isPC ? (
        <Box className="flex flex-row justify-between">
          <Box className="flex flex-row gap-x-4">
            <Box className="text-base text-black-1 flex flex-row">
              <Text
                className="text-primary-2 cursor-pointer"
                onClick={handleToLiiga}
              >
                内定者はこちら
              </Text>
            </Box>
          </Box>
          <Box
            className="flex flex-row text-primary-2 items-center text-base cursor-pointer"
            onClick={closePopup}
          >
            <MdClose size={14} />
            <Text>閉じる</Text>
          </Box>
        </Box>
      ) : (
        <Box className="flex flex-row justify-between">
          <Box className="flex flex-row gap-x-4 text-black-1 items-center">
            <Box className="text-sm flex">
              <Text
                className="text-primary-1 cursor-pointer"
                onClick={handleToLiiga}
              >
                内定者はこちら
              </Text>
            </Box>
          </Box>
          <Box
            className="flex flex-row text-primary-2 items-center text-base cursor-pointer"
            onClick={closePopup}
          >
            <MdClose size={14} />
          </Box>
        </Box>
      )}
    </>
  );
};
