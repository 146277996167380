import {Dialog} from '@mantine/core';
import {useDisclosure} from '@mantine/hooks';
import {BasicButton} from '@packages/ui';
import {addDays} from 'date-fns';
import {useRouter} from 'next/router';
import {parseCookies, setCookie} from 'nookies';
import {MdClose} from 'react-icons/md';
import {
  BOX_DIR_FIRST,
  INQUIRY_FOREIGN_UNIV,
  LOGIN,
  LOGOUT,
  MAIL_AUTH,
  MYNAVI_FAIR_CAREER_DISCOVERY,
  MYNAVI_FAIR_TEASER,
  QUIT,
  QUIT_THANK_TOU,
  REGISTS_DIR_FIRST,
  REMIND_PASS,
  RESET_PASS,
  STUDENT_CARD,
  STUDENT_CARD_APPLIED,
} from '../../config/constants/routes';
import {useAuth} from '../../core/usecases/useAuth';
import {getMailAuthLinkWithParamsHooks} from '../services/getMailAuthLinkWithParamsHooks';
import {useMediaQuery} from '../services/useMediaQuery';
import {useWindowSize} from '../services/useWindowSize';

const REGISTRATION_NAVIGATION_FLOATING_COOKIE_KEY =
  'hide_user_registration_link';

export const RegistrationNavigationFloating: React.FC = () => {
  const [opened, {close}] = useDisclosure(true, {
    onClose() {
      setCookie(null, REGISTRATION_NAVIGATION_FLOATING_COOKIE_KEY, '1', {
        path: '/',
        expires: addDays(new Date(), 1),
      });
    },
  });
  const router = useRouter();
  const {isPC} = useMediaQuery();
  const {isSignedIn} = useAuth();
  const cookies = parseCookies();
  const isClosed =
    REGISTRATION_NAVIGATION_FLOATING_COOKIE_KEY in cookies &&
    cookies[REGISTRATION_NAVIGATION_FLOATING_COOKIE_KEY] === '1';
  // 表示しないページリスト
  const hidePagesDir = [
    MAIL_AUTH,
    REGISTS_DIR_FIRST,
    STUDENT_CARD,
    INQUIRY_FOREIGN_UNIV,
    STUDENT_CARD_APPLIED,
    LOGIN,
    LOGOUT,
    REMIND_PASS,
    RESET_PASS,
    QUIT,
    QUIT_THANK_TOU,
    MYNAVI_FAIR_TEASER,
    MYNAVI_FAIR_CAREER_DISCOVERY,
    BOX_DIR_FIRST, // UI上表示するとナビゲーションが隠れてしまうため
  ];
  const isHidePage = hidePagesDir.some((page) =>
    router.pathname.includes(page),
  );
  const {width: windowWidth} = useWindowSize();

  const mailAuthLink = getMailAuthLinkWithParamsHooks({});

  return (
    <>
      <Dialog
        opened={opened && isPC && !isSignedIn && !isClosed && !isHidePage}
        size={windowWidth ?? 'lg'}
        radius="sm"
        position={{bottom: 0, left: 0}}
        transition="slide-up"
        transitionDuration={300}
        transitionTimingFunction="ease"
        className="bg-overlay-3 -translate-x-1/2 pt-[30px] pb-16 max-w-full"
      >
        <div className="flex justify-center items-center gap-8 mx-auto">
          <div className="relative w-full max-w-[340px]">
            <div className="absolute bg-white border-accent-1 border-collapse border-[1px] rounded-full top-[-16px] left-[50%] -translate-x-1/2 pt-[3px] pb-[2px] px-12">
              <p className="text-xs font-bold text-accent-1 leading text-center">
                会員限定コンテンツ多数！
              </p>
            </div>
            <BasicButton
              isPrimary={true}
              isLarge={true}
              className="w-full max-w-[340px] mx-auto"
              label="無料会員登録はこちら"
              onClick={() => {
                location.href = mailAuthLink;
              }}
            />
          </div>
          <div className="bg-black-3 rounded-full w-fit h-fit p-8">
            <MdClose className="text-white-1 cursor-pointer" onClick={close} />
          </div>
        </div>
      </Dialog>
    </>
  );
};
