import {Box, Text} from '@mantine/core';
import type React from 'react';
import {useState} from 'react';
import {MdClose} from 'react-icons/md';
import {useMediaQuery} from '../../../shared/services/useMediaQuery';
import {GmailModalSp} from './Container/GmailModalSp';

type Props = {
  closePopup: () => void;
};

export const GmailPromo: React.FC<Props> = ({closePopup}) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const {isPC} = useMediaQuery();
  return (
    <>
      {isPC ? (
        <Box className="flex flex-row justify-between items-center">
          <Box className="flex flex-row text-base text-black">
            【Gmailをお使いの方へ】大切なお知らせを見逃している可能性があります。
          </Box>
          <Box className="flex flex-row items-center gap-12">
            <Box
              className="cursor-pointer flex items-center justify-center border-primary border-[1px] bg-white rounded-[8px] py-8 px-12"
              onClick={() => {
                setIsOpenModal(true);
              }}
            >
              <Text className="text-sm text-primary font-bold">詳しく見る</Text>
            </Box>
            <Box
              className="w-24 h-24 flex items-center justify-center rounded-full bg-black-3 cursor-pointer"
              onClick={closePopup}
            >
              <MdClose size={24} color="white" />
            </Box>
          </Box>
          <GmailModalSp
            isOpenModal={isOpenModal}
            setIsOpenModal={setIsOpenModal}
            closePopup={closePopup}
          />
        </Box>
      ) : (
        <Box
          className="flex flex-row justify-between text-sm"
          onClick={() => {
            setIsOpenModal(true);
          }}
        >
          <Text>
            【Gmailをお使いの方へ】大切なお知らせを見逃している可能性があります。
          </Text>
          <Box
            className="flex flex-row text-primary-1 items-center"
            onClick={(e) => {
              e.stopPropagation();
              closePopup();
            }}
          >
            <MdClose size={16} className="text-black-3" />
          </Box>
          <GmailModalSp
            isOpenModal={isOpenModal}
            setIsOpenModal={setIsOpenModal}
            closePopup={closePopup}
          />
        </Box>
      )}
    </>
  );
};
