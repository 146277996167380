import type {FC} from 'react';
import {MdAccountCircle} from 'react-icons/md';
import type {UserType} from '../../../../core/usecases/auth/AuthContext';
import {HeaderMenu} from '../Menu';
import {LoginRegistButton} from './LoginRegistButton';

type Props = {
  shownUserMenu: boolean;
  showUserMenu: () => void;
  hideUserMenu: () => void;
  scoutsCount: number;
  interestsCount: number;
  isSignedIn?: boolean;
  hasNotification: boolean;
  user?: UserType;
  signOut?: () => void;
};
export const UserMenu: FC<Props> = ({
  shownUserMenu,
  showUserMenu,
  hideUserMenu,
  scoutsCount,
  interestsCount,
  isSignedIn,
  hasNotification,
  user,
  signOut,
}) => {
  return isSignedIn ? (
    <button
      className="relative flex items-center gap-8"
      onClick={showUserMenu}
      onMouseEnter={showUserMenu}
      onMouseLeave={hideUserMenu}
      aria-label="ユーザーメニューを開く"
    >
      <div className="relative">
        <MdAccountCircle size={24} />
        {hasNotification && (
          <div className="absolute right-0 top-0 flex h-8 w-8 rounded-full bg-notification" />
        )}
      </div>
      <div className="text-sm font-bold">{user?.familyName}さん</div>
      {shownUserMenu && (
        <div className="absolute right-0 top-[21px] z-50 pt-12">
          <div className="overflow-hidden rounded-12 bg-white shadow-modal">
            <HeaderMenu
              onItemClick={hideUserMenu}
              scoutsCount={scoutsCount}
              interestsCount={interestsCount}
              showMyPage={isSignedIn}
              onLogout={signOut}
              className="w-[184px] text-black"
            />
          </div>
        </div>
      )}
    </button>
  ) : (
    <LoginRegistButton />
  );
};
