import {destroyCookie} from 'nookies';
import {
  cookieApiRefreshTokenKey,
  cookieApiTokenKey,
  cookieSessionKey,
} from '../../../server/token';
import {
  cookieSessionIdKey,
  cookieUserIdKey,
} from '../../../server/token/cookieUserInfoKey';

export const destroyCookieWithLogout = () => {
  destroyCookie(null, cookieApiTokenKey, {path: '/'});
  destroyCookie(null, cookieApiRefreshTokenKey, {path: '/'});
  destroyCookie(null, cookieSessionKey, {path: '/'});
  destroyCookie(null, cookieSessionIdKey, {path: '/'});
  destroyCookie(null, cookieUserIdKey, {path: '/'});
};
