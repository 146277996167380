import {parseCookies} from 'nookies';
import type {FC} from 'react';
import {validateCookie} from '../../../../core/usecases/auth/checkOauthTokenExistence';
import {useAuthAndCounts} from '../../../../core/usecases/auth/useAuthAndCounts';
import {GeneralHeaderPresentation} from './presentation';

type Props = {
  className?: string;
};

export const GeneralHeaderContainer: FC<Props> = ({className}) => {
  const cookie = parseCookies();
  const {isValid} = validateCookie(cookie);
  const {scoutsCount, interestsCount} = useAuthAndCounts();

  return (
    <GeneralHeaderPresentation
      className={className}
      hasValidCookie={isValid}
      scoutsCount={scoutsCount}
      interestsCount={interestsCount}
    />
  );
};
