import classNames from 'classnames';
import {useRouter} from 'next/router';
import {type FC, type MouseEvent, useCallback} from 'react';
import {QUERY_PARAMS_FOR_REDIRECT_FROM_LOGIN_PAGE} from '../../../core/entities/login/constants';

type Props = {
  showMyPage?: boolean;
  scoutsCount?: number;
  interestsCount?: number;
  onItemClick?: () => void;
  onLogout?: () => void;
  className?: string;
};

type MenuItem = {
  id?: string;
  href?: string;
  label: string;
};

const items = [
  {id: 'login', href: '/login', label: 'ログイン'},
  {id: 'mypage', href: '/', label: 'マイページ'},
  {id: 'scouts', href: '/scouts', label: 'スカウト'},
  {id: 'interests', href: '/myInterests', label: '興味あり'},
  {href: '/schedule', label: 'スケジュール'},
  {href: '/mypage/profile', label: 'プロフィールを編集'},
  {href: '/mypage/reports', label: '選考体験記の管理'},
  {href: '/mypage/reports/add', label: '選考体験記の投稿'},
  {href: '/mypage/entrysheets', label: 'エントリシートの管理'},
  {href: '/mypage/es/new', label: 'エントリシートの投稿'},
  // { href: "/mypage/setting", label: "各種設定・その他" },
  {id: 'logout', label: 'ログアウト'},
] satisfies MenuItem[];

export const HeaderMenu: FC<Props> = ({
  showMyPage,
  scoutsCount,
  interestsCount,
  onItemClick,
  onLogout,
  className,
}) => {
  const router = useRouter();
  const onClickFactory = useCallback(
    (item: MenuItem) => (e: MouseEvent) => {
      e.stopPropagation();
      const {id} = item;
      if (id === 'logout') {
        onLogout?.();
      }

      onItemClick?.();
    },
    [onItemClick, onLogout],
  );

  return (
    <ul
      className={classNames(
        'bg-white divide-line pc:text-base text-md flex flex-col divide-y py-4 px-12 w-[184px]',
        className,
      )}
    >
      {items.map((item) => {
        const {id, href, label} = item;
        let notificationCount = 0;

        if ((!showMyPage && id !== 'login') || (showMyPage && id === 'login'))
          return;

        switch (id) {
          case 'mypage': {
            if (!showMyPage) return null;
            break;
          }

          case 'scouts': {
            notificationCount = scoutsCount ?? 0;
            break;
          }

          case 'interests': {
            notificationCount = interestsCount ?? 0;
            break;
          }

          default: {
            notificationCount = 0;
          }
        }

        return (
          <li key={id || item.href}>
            {id === 'logout' ? (
              <button
                onClick={onClickFactory(item)}
                className="flex justify-between p-8 cursor-pointer w-full"
              >
                <span>{label}</span>
              </button>
            ) : (
              <a
                href={
                  id === 'login'
                    ? `${href}?${QUERY_PARAMS_FOR_REDIRECT_FROM_LOGIN_PAGE}=${router.asPath}`
                    : href
                }
                onClick={onClickFactory(item)}
                className="flex justify-between p-8 cursor-pointer"
              >
                <span>{label}</span>
                {notificationCount > 0 && (
                  <span className="min-w-[28px] rounded-full bg-notification-2 p-4 text-center text-sm font-bold text-white">
                    {notificationCount >= 100 ? '99+' : notificationCount}
                  </span>
                )}
              </a>
            )}
          </li>
        );
      })}
    </ul>
  );
};
