import {
  ApiResponseShapeError,
  type AppError,
  BadRequestError,
  ConflictError,
  Err,
  NetworkError,
  NotAuthorizedError,
  NotFoundError,
  Ok,
  type Result,
  UnknownError,
} from '@packages/utils';
import axios, {
  type AxiosResponseHeaders,
  type AxiosError,
  type AxiosInstance,
} from 'axios';
import type {ZodType} from 'zod/lib/types';
import {
  type ClientContextInterface,
  getCurrentPage,
  getGtagData,
  getReferer,
} from './ClientContextInterface';
import {baseUrl} from './baseUrl';

export class ClientContextClass implements ClientContextInterface {
  private client: AxiosInstance;
  constructor(token?: string) {
    const currentPage = getCurrentPage();
    const referer = getReferer();
    const {sessionId, pseudoId} = getGtagData();

    this.client = axios.create({
      baseURL: baseUrl(),
      headers: {
        'x-howtv-clienttype': 'web',
        'x-howtv-token': `Howtv ${token ?? 'undefined'}`,
        'x-howtv-current-path': `${currentPage}`,
        'x-howtv-referer': `${referer}`,
        'x-howtv-ga-session-id': `${sessionId}`,
        'x-howtv-ga-pseudo-id': `${pseudoId}`,
      },
      withCredentials: true,
    });
  }

  setToken(token: string) {
    const currentPage = getCurrentPage();
    const referer = getReferer();
    const {sessionId, pseudoId} = getGtagData();

    this.client = axios.create({
      baseURL: baseUrl(),
      headers: {
        'x-howtv-clienttype': 'web',
        'x-howtv-token': `Howtv ${token}`,
        'x-howtv-current-path': `${currentPage}`,
        'x-howtv-referer': `${referer}`,
        'x-howtv-ga-session-id': `${sessionId}`,
        'x-howtv-ga-pseudo-id': `${pseudoId}`,
      },
    });
  }

  // 結果だけでなく、headerも返す
  setRegisterTempToken(token: string) {
    const currentPage = getCurrentPage();
    const referer = getReferer();
    const {sessionId, pseudoId} = getGtagData();

    this.client = axios.create({
      baseURL: baseUrl(),
      headers: {
        Authorization: `Bearer ${token}`,
        'x-howtv-current-path': `${currentPage}`,
        'x-howtv-referer': `${referer}`,
        'x-howtv-ga-session-id': `${sessionId}`,
        'x-howtv-ga-pseudo-id': `${pseudoId}`,
      },
    });
  }

  setMultipartFormDataWithToken(token: string) {
    const currentPage = getCurrentPage();
    const referer = getReferer();
    const {sessionId, pseudoId} = getGtagData();

    this.client = axios.create({
      baseURL: baseUrl(),
      headers: {
        'x-howtv-clienttype': 'web',
        'x-howtv-token': `Howtv ${token}`,
        'Content-Type': 'multipart/form-data',
        'x-howtv-current-path': `${currentPage}`,
        'x-howtv-referer': `${referer}`,
        'x-howtv-ga-session-id': `${sessionId}`,
        'x-howtv-ga-pseudo-id': `${pseudoId}`,
      },
    });
  }

  // 結果だけでなく、headerも返す
  async getWithResponseHeader<T>(
    path: string,
    validator: ZodType<T>,
  ): Promise<{
    result: Result<T, AppError>;
    headers: AxiosResponseHeaders;
  }> {
    try {
      const result = await this.client.get(path);
      const parsed = validator.safeParse(result.data);
      if (parsed.success) {
        return {
          result: Ok(parsed.data),
          headers: result.headers,
        };
      }

      return {
        result: Err(ApiResponseShapeError),
        headers: result.headers,
      };
    } catch (error: any) {
      return {
        result: this.handleError<T>(error),
        headers: {},
      };
    }
  }

  async get<T>(
    path: string,
    validator: ZodType<T>,
  ): Promise<Result<T, AppError>> {
    try {
      const result = await this.client.get(path);
      const parsed = validator.safeParse(result.data);
      if (parsed.success) {
        return Ok(parsed.data);
      }

      if (process.env.NEXT_PUBLIC_RELEASE_STAGE !== 'production') {
        console.log('parsed.error', parsed.error);
      }

      return Err(ApiResponseShapeError);
    } catch (error: any) {
      if (error.isAxiosError === true) {
        const e = error as AxiosError;
        const res = e.response;
        if (!res) return Err(UnknownError);

        if (res.status === 404) {
          return Err(NotFoundError);
        }

        if (res.status === 401) {
          return Err(NotAuthorizedError);
        }

        if (res.status === 400) {
          return Err(BadRequestError);
        }

        return Err(NetworkError);
      }

      return Err(UnknownError);
    }
  }

  async post<T, U>(
    path: string,
    validator: ZodType<T>,
    body: U,
  ): Promise<Result<T, AppError>> {
    try {
      const result = await this.client.post(path, body);
      const parsed = validator.safeParse(result.data);
      if (parsed.success) {
        return Ok(parsed.data);
      }

      return Err(ApiResponseShapeError);
    } catch (error: any) {
      return this.handleError<T>(error);
    }
  }

  async put<T, U>(
    path: string,
    validator: ZodType<T>,
    body: U,
  ): Promise<Result<T, AppError>> {
    try {
      const result = await this.client.put(path, body);
      const parsed = validator.safeParse(result.data);
      if (parsed.success) {
        return Ok(parsed.data);
      }

      return Err(ApiResponseShapeError);
    } catch (error: any) {
      return this.handleError<T>(error);
    }
  }

  async delete<T>(
    path: string,
    validator: ZodType<T>,
  ): Promise<Result<T, AppError>> {
    try {
      const result = await this.client.delete(path);
      const parsed = validator.safeParse(result.data);
      if (parsed.success) {
        return Ok(parsed.data);
      }

      return Err(ApiResponseShapeError);
    } catch (error: any) {
      return this.handleError<T>(error);
    }
  }

  setGoogleCalendarToken({
    accessToken,
    googleAccessToken,
    googleRefreshToken,
    expiry,
  }: {
    accessToken: string;
    googleAccessToken: string;
    googleRefreshToken: string;
    expiry: string;
  }) {
    this.client = axios.create({
      baseURL: baseUrl(),
      headers: {
        'x-howtv-clienttype': 'web',
        'x-howtv-token': `Howtv ${accessToken}`,
        'access-token': googleAccessToken,
        'refresh-token': googleRefreshToken,
        expiry,
      },
    });
  }

  // 共通のエラー処理
  private handleError<T>(error: any): Result<T, AppError> {
    if (error.isAxiosError) {
      const e = error as AxiosError;
      const statusCode = e.response?.status;
      if (statusCode === undefined) {
        return Err(UnknownError);
      }

      if (statusCode === 401) {
        return Err(NotAuthorizedError);
      }

      if (statusCode === 404) {
        return Err(NotFoundError);
      }

      if (statusCode === 409) {
        return Err(ConflictError);
      }

      return Err(NetworkError);
    }

    return Err(UnknownError);
  }
}
