import {Input, Transition} from '@mantine/core';
import {type FC, useEffect, useRef, useState} from 'react';
import {FaSearch} from 'react-icons/fa';
import {IoSearchCircleOutline} from 'react-icons/io5';

type Props = {
  shownSearch: boolean;
  showSearch: () => void;
  hideSearch: () => void;
  handleSearchSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  searchQuery: string;
  setSearchQuery: (query: string) => void;
};
export const SearchBar: FC<Props> = ({
  shownSearch,
  showSearch,
  hideSearch,
  handleSearchSubmit,
  searchQuery,
  setSearchQuery,
}) => {
  const defaultDuration = 500;
  const [duration, setDuration] = useState(defaultDuration);
  const searchBarRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        searchBarRef.current &&
        !searchBarRef.current.contains(event.target as Node)
      ) {
        hideSearch();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [hideSearch]);

  const scaleX = {
    in: {opacity: 1, transform: 'scaleX(1)'},
    out: {opacity: 0, transform: 'scaleX(0)', transitionDuration: '0.1s'},
    common: {transformOrigin: 'right'},
    transitionProperty: 'transform, opacity',
  };

  return (
    <div className="flex items-center" onClick={showSearch} ref={searchBarRef}>
      {!shownSearch && (
        <button aria-label="検索する">
          <IoSearchCircleOutline size={24} />
        </button>
      )}
      <Transition
        mounted={shownSearch}
        transition={scaleX}
        duration={duration}
        timingFunction="ease"
        onEntered={() => {
          setDuration(0);
        }}
      >
        {(styles: React.CSSProperties) => (
          <form onSubmit={handleSearchSubmit} style={styles}>
            <Input
              type="search"
              icon={<FaSearch />}
              radius="xl"
              size="xs"
              className="text-sm w-[200px]"
              placeholder="企業や募集、コラムを検索"
              value={searchQuery}
              onChange={(e) => {
                setSearchQuery(e.target.value);
              }}
            />
          </form>
        )}
      </Transition>
    </div>
  );
};
