import type {AppError, Result} from '@packages/utils';
import type {ZodType} from 'zod/lib/types';

export type ClientContextInterface = {
  setToken: (token: string) => void;

  get<T>(path: string, validator: ZodType<T>): Promise<Result<T, AppError>>;
  post<T, U>(
    path: string,
    validator: ZodType<T>,
    body: U,
  ): Promise<Result<T, AppError>>;
  put<T, U>(
    path: string,
    validator: ZodType<T>,
    body: U,
  ): Promise<Result<T, AppError>>;
  delete<T>(path: string, validator: ZodType<T>): Promise<Result<T, AppError>>;
};

// 現在のページを取得する
export const getCurrentPage = () => {
  if (typeof window === 'undefined') {
    // @ts-expect-error: GlobalThis does not have an index signature
    const currentPage = globalThis?.req?.resolvedUrl;
    return typeof currentPage === 'string' ? currentPage : undefined;
  }

  return window.location.href;
};

// リファラーを取得する
export const getReferer = () => {
  if (typeof document === 'undefined') {
    // @ts-expect-error: GlobalThis does not have an index signature
    const referer = globalThis?.req?.headers?.referer;
    return typeof referer === 'string' ? referer : undefined;
  }

  return document?.referrer;
};

// Gtagデータの型定義
type GtagData = {
  sessionId: string | undefined;
  pseudoId: string | undefined;
};

// Gtagを取得する
export const getGtagData = (): GtagData => {
  if (typeof document === 'undefined') {
    return {sessionId: undefined, pseudoId: undefined};
  }

  const cookieValues = document?.cookie.split('; ');
  const sessionParts = cookieValues
    .find((value) => value.startsWith('_ga_C3EXT120D0='))
    ?.split('=')[1]
    ?.split('.');
  const sessionId = sessionParts?.[2] ? `${sessionParts[2]}` : undefined;
  const pseudoParts = cookieValues
    .find((value) => value.startsWith('_ga='))
    ?.split('=')[1]
    ?.split('.');
  const pseudoId =
    pseudoParts?.[2] && pseudoParts[3]
      ? `${pseudoParts[2]}.${pseudoParts[3]}`
      : undefined;
  return {sessionId, pseudoId};
};
