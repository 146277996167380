import type {FC} from 'react';
import {FaExternalLinkAlt} from 'react-icons/fa';

export const GuestHeaderBar: FC = () => (
  <div className="flex w-full bg-background-footer justify-center p-16">
    <div className="flex w-[1200px] justify-between items-center text-white text-sm">
      <div>
        外資・日系トップ企業を目指す学生のための就職活動サイト
        「外資就活ドットコム」
      </div>
      <div className="flex items-center gap-8">
        <a
          href="https://go.gaishishukatsu.com/biz"
          target="_blank"
          rel="noopener noreferrer"
        >
          採用担当者様はこちら
        </a>
        <div className="ml-[-6px] mr-24 cursor-pointer">
          <FaExternalLinkAlt />
        </div>
      </div>
    </div>
  </div>
);
