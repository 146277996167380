export const searchCompaniesFromIndustryItems = [
  {href: '/company/industry/1', label: 'コンサル・シンクタンク'},
  {href: '/company/industry/2', label: '外資系金融・証券'},
  {href: '/company/industry/3', label: '日系金融・証券'},
  {href: '/company/industry/5', label: '商社'},
  {href: '/company/industry/4', label: '外資系メーカー・サービス'},
  {href: '/company/industry/8', label: '日系メーカー・サービス'},
  {href: '/company/industry/6', label: '国家公務員・公的機関'},
  {href: '/company/industry/7', label: 'ITサービス'},
  {href: '/company/industry/11', label: 'デベロッパー・建設'},
  {href: '/company/industry/9', label: 'マスコミ・広告'},
] satisfies Array<{href: string; label: string}>;

export const searchReportsItems = [
  {href: '/reports?type=selection', label: '本選考'},
  {href: '/reports?type=intern', label: 'インターン'},
  {href: '/reports?type=entrysheet', label: 'エントリーシート'},
] satisfies Array<{href: string; label: string}>;

export const searchReportsFromIndustryItems = [
  {
    href: '/reports/industry/1',
    label: 'コンサル・シンクタンク',
  },
  {
    href: '/reports/industry/2',
    label: '外資系金融・証券',
  },
  {
    href: '/reports/industry/3',
    label: '日系金融・証券',
  },
  {
    href: '/reports/industry/5',
    label: '商社',
  },
  {
    href: '/reports/industry/4',
    label: '外資系メーカー・サービス',
  },
  {
    href: '/reports/industry/8',
    label: '日系メーカー・サービス',
  },
  {
    href: '/reports/industry/6',
    label: '国家公務員・公的機関',
  },
  {
    href: '/reports/industry/7',
    label: 'ITサービス',
  },
  {
    href: '/reports/industry/11',
    label: 'デベロッパー・建設',
  },
  {
    href: '/reports/industry/9',
    label: 'マスコミ・広告',
  },
] satisfies Array<{href: string; label: string}>;

export const searchReportsFromDepartmentItems = [
  {
    href: '/reports/department/1',
    label: 'コンサルタント',
  },
  {
    href: '/reports/department/3',
    label: '投資銀行部門（IBD）',
  },
  {
    href: '/reports/department/4',
    label: '銀行／証券マーケット業務',
  },
  {
    href: '/reports/department/5',
    label: 'ミドル・バックオフィス',
  },
  {
    href: '/reports/department/6',
    label: 'マーケティング',
  },
  {
    href: '/reports/department/7',
    label: 'ファイナンス',
  },
  {
    href: '/reports/department/8',
    label: 'セールス・営業',
  },
  {
    href: '/reports/department/9',
    label: 'アクチュアリー・クオンツ',
  },
  {
    href: '/reports/department/10',
    label: 'システムエンジニア',
  },
  {
    href: '/reports/department/13',
    label: 'R&D（技術研究職）',
  },
  {
    href: '/reports/department/17',
    label: '総合職',
  },
  {
    href: '/reports/department/22',
    label: 'ジャーナリスト',
  },
  {
    href: '/reports/department/30',
    label: 'プログラマー',
  },
  {
    href: '/reports/department/31',
    label: 'ソフトウェアエンジニア',
  },
  {
    href: '/reports/department/32',
    label: 'データサイエンティスト',
  },
] satisfies Array<{href: string; label: string}>;

export const footerMenuItems = [
  {href: '/recruiting_info', label: '募集を探す'},
  {href: '/reports', label: 'ES・体験記'},
  {href: '/column/categories', label: '就活コラム'},
  {href: '/questions', label: 'コミュニティ'},
  {href: '/box', label: '相談室'},
  {href: '/engineer', label: 'エンジニア'},
  {
    href: 'https://event.gaishishukatsu.com/',
    label: 'イベント',
    target: '_blank',
  },
] satisfies Array<{href: string; label: string; target?: string}>;

export const footerMenuItems2 = [
  {href: '/faq', label: 'ヘルプ・お問い合わせ'},
  {href: '/disclaimer', label: '著作権・免責事項'},
  {href: '/terms', label: '利用規約'},
  {href: '/service_privacy_policy', label: 'プライバシーポリシー'},
  {href: '/corporate_privacy_policy', label: '個人情報保護方針'},
  {
    href: 'https://biz.gaishishukatsu.com/contact/',
    label: '採用ご担当者の方はこちら',
  },
  {
    href: 'http://howtelevision.co.jp/',
    label: '運営会社',
    target: '_blank',
    rel: 'noreferrer',
  },
  {href: 'https://liiga.me/', label: 'Liiga'},
  {href: 'https://mond.how/', label: 'mond'},
] satisfies Array<{href: string; label: string; target?: string; rel?: string}>;

export const engineerFooterMenuItems = [
  {href: '#recruiting_info', label: '募集を探す'},
  {href: '#companies', label: '企業を探す'},
  {href: '#company_reports', label: '選考対策'},
  {href: '#columns', label: 'コラム'},
  {href: '#events', label: 'イベント'},
] satisfies Array<{href: string; label: string}>;

export const gssktAppLink = {
  ios: 'https://gsskt.onelink.me/TB3c/i81csz59',
  android:
    'https://play.google.com/store/apps/details?id=com.gaishishukatsu.Gaishishukatsu&hl=ja&gl=US',
} satisfies Record<string, string>;
